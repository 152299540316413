import { type Coords } from '@glow/shared/lib/coords';
import { type City } from '@/api/cities';
import { type PaginationMeta } from '@glow/shared/lib/http';

export type PartnerType = 'master' | 'company';
export type PluralPartnerType = 'masters' | 'companies';

export const SORT_OPTIONS = [
  'popularity',
  'distance',
  'reviews',
  'price',
] as const;
export type PartnerSort = (typeof SORT_OPTIONS)[number];
export const PARTNER_DEFAULT_SORT: PartnerSort = 'popularity';

export type PartnerFilters = {
  city?: string;
  address?: string;
  metroStation?: number;
  radius?: number;

  specialization?: string;
  service?: string;

  isHomeVisitAvailable?: boolean;
  priceRange?: [number, number];
  appointableAt?: Date;
  sort: PartnerSort;
  bounds?: number[];
};

export type PartnerExperienceRecord = {
  name: string;
  startYear: number;
  endYear: number | null;
};

export type PartnerExperienceRecordGroup = {
  name: string;
  records: PartnerExperienceRecord[];
};

export type PartnerCatalogMeta = PaginationMeta & {
  city: City;
  address: string;
  center: Coords;
  title: string;
  headTitle: string;
  shortTitle: string;
  description: string;
  isIndexable: boolean;
};

export type PartnerDetailsMeta = {
  description: string;
};

export type PartnerMapObject = {
  id: number;
  slug: string;
  partnerType: PartnerType;
  location: Coords;
  citySlug: string;
  name: string;
  avatarUrl: string | null;
};
